import React from "react";
import { Button, FlexGrid, Row, Column } from "@carbon/react";
import {
  LogoGithub,
  ArrowDown as ArrowDownIcon,
  Dashboard as DashboardIcon,
} from "@carbon/icons-react";
import { useAuth } from "@/apx/AuthManager";
import ImageShowcase from "@/app/components/ImageShowcase";
import { getConfig } from "@/config";

const Hero = () => {
  const { isAuthenticated } = useAuth();

  const scrollToNextViewport = (e: React.MouseEvent) => {
    e.preventDefault();
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const leftUrl = "img/screenshots/mergeai.webp";
  const leftUrlDark = "img/screenshots/mergeai-dark.webp";
  const centerUrl = "img/screenshots/mergeview.webp";
  const centerUrlDark = "img/screenshots/mergeview-dark.webp";
  const rightUrl = "img/screenshots/applymerge.webp";
  const rightUrlDark = "img/screenshots/applymerge-dark.webp";

  return (
    <FlexGrid fullWidth>
      <Row
        className="hero-section"
        style={{ height: "100vh", position: "relative" }}
      >
        <Column
          lg={16}
          md={8}
          sm={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%", maxWidth: "40rem", marginTop: "0px" }}>
            <Row>
              <Column lg={16} md={8} sm={4}>
                <h1
                  style={{
                    fontSize: "3rem",
                    fontWeight: "600",
                    lineHeight: "1.2",
                    textAlign: "center",
                  }}
                >
                  Simplify Merging and Resolve Code Conflicts
                </h1>
              </Column>
            </Row>
            <Row>
              <Column lg={16} md={8} sm={4}>
                <p
                  style={{
                    marginTop: "32px",
                    fontSize: "1.125rem",
                    fontWeight: "500",
                    lineHeight: "1.5",
                    textAlign: "center",
                  }}
                >
                  code input help you simplify the process of merging and
                  resolving code conflicts. It's the perfect tool for teams of
                  all sizes.
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                lg={16}
                md={8}
                sm={4}
                style={{
                  marginTop: "2.5rem",
                  display: "flex",
                  justifyContent: "center",
                  gap: "1.5rem",
                  flexWrap: "wrap",
                }}
              >
                {!isAuthenticated ? (
                  <Button
                    href={`${getConfig("api").baseUrl}/login`}
                    renderIcon={LogoGithub}
                    style={{ zIndex: 100 }}
                  >
                    Login with Github
                  </Button>
                ) : (
                  <Button
                    href="/dashboard"
                    renderIcon={DashboardIcon}
                    style={{ zIndex: 100 }}
                  >
                    Go to Dashboard
                  </Button>
                )}
                <Button
                  href="#"
                  onClick={scrollToNextViewport}
                  renderIcon={ArrowDownIcon}
                  kind="secondary"
                  style={{ zIndex: 100 }}
                >
                  Learn More
                </Button>
              </Column>
            </Row>
          </div>
        </Column>
        <ImageShowcase
          leftImageLight={leftUrl}
          leftImageDark={leftUrlDark}
          centerImageLight={centerUrl}
          centerImageDark={centerUrlDark}
          rightImageLight={rightUrl}
          rightImageDark={rightUrlDark}
        />
      </Row>
    </FlexGrid>
  );
};

export default Hero;
