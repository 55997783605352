// @ts-ignore
import C4DCardEyebrow from "@carbon/ibmdotcom-web-components/es/components-react/card/card-eyebrow";
// @ts-ignore
import C4DCardHeading from "@carbon/ibmdotcom-web-components/es/components-react/card/card-heading";
// @ts-ignore
import C4DCardInCard from "@carbon/ibmdotcom-web-components/es/components-react/card-in-card/card-in-card";
// @ts-ignore
import C4DCardCTAFooter from "@carbon/ibmdotcom-web-components/es/components-react/cta/card-cta-footer";
// @ts-ignore
import C4DCardInCardImage from "@carbon/ibmdotcom-web-components/es/components-react/card-in-card/card-in-card-image";

import { ArrowRight } from "@carbon/react/icons";
import { FlexGrid } from "@carbon/react";
import React from "react";
import CarbonImage from "@/app/components/CarbonImage";
import Link from "next/link";

export default function HomeFeature() {
  return (
    <FlexGrid fullWidth style={{ maxWidth: "1584px", margin: "0 auto" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >
          <Link href="/blog/merge-editor" legacyBehavior passHref>
            <C4DCardInCard style={{ width: "100%" }}>
              <C4DCardInCardImage slot="image" alt="Image alt text">
                <CarbonImage
                  media="(min-width: 1280px)"
                  lightSrc="/img/screenshots/mergeview.webp"
                  darkSrc="/img/screenshots/mergeview-dark.webp"
                ></CarbonImage>
                <CarbonImage
                  media="(min-width: 672px)"
                  lightSrc="/img/screenshots/mergeview.webp"
                  darkSrc="/img/screenshots/mergeview-dark.webp"
                ></CarbonImage>
                <CarbonImage
                  media="(min-width: 320px)"
                  lightSrc="/img/screenshots/mergeview.webp"
                  darkSrc="/img/screenshots/mergeview-dark.webp"
                ></CarbonImage>
              </C4DCardInCardImage>
              <C4DCardEyebrow>Editor</C4DCardEyebrow>
              <C4DCardHeading aria-level={2}>
                Explore our Merge Editor
              </C4DCardHeading>
              <C4DCardCTAFooter>
                <ArrowRight size={20} />
              </C4DCardCTAFooter>
            </C4DCardInCard>
          </Link>
        </div>

        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >
          <Link href="/blog/ai-merge-conflict" legacyBehavior passHref>
            <C4DCardInCard style={{ width: "100%" }}>
              <C4DCardInCardImage slot="image" alt="Image alt text">
                <CarbonImage
                  media="(min-width: 1312px)"
                  lightSrc="/img/screenshots/mergeai.webp"
                  darkSrc="/img/screenshots/mergeai-dark.webp"
                ></CarbonImage>
                <CarbonImage
                  media="(min-width: 672px)"
                  lightSrc="/img/screenshots/mergeai.webp"
                  darkSrc="/img/screenshots/mergeai-dark.webp"
                ></CarbonImage>
                <CarbonImage
                  media="(min-width: 320px)"
                  lightSrc="/img/screenshots/mergeai.webp"
                  darkSrc="/img/screenshots/mergeai-dark.webp"
                ></CarbonImage>
              </C4DCardInCardImage>
              <C4DCardEyebrow>AI</C4DCardEyebrow>
              <C4DCardHeading aria-level="2">
                Use AI Models to resolve conflicts
              </C4DCardHeading>
              <C4DCardCTAFooter>
                <ArrowRight size={20} />
              </C4DCardCTAFooter>
            </C4DCardInCard>
          </Link>
        </div>

        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >
          <Link href="/blog/remote-merge" passHref legacyBehavior>
            <C4DCardInCard style={{ width: "100%" }}>
              <C4DCardInCardImage slot="image" alt="Image alt text">
                <CarbonImage
                  media="(min-width: 1312px)"
                  lightSrc="/img/screenshots/applymerge.webp"
                  darkSrc="/img/screenshots/applymerge-dark.webp"
                ></CarbonImage>
                <CarbonImage
                  media="(min-width: 672px)"
                  lightSrc="/img/screenshots/applymerge.webp"
                  darkSrc="/img/screenshots/applymerge-dark.webp"
                ></CarbonImage>
                <CarbonImage
                  media="(min-width: 320px)"
                  lightSrc="/img/screenshots/applymerge.webp"
                  darkSrc="/img/screenshots/applymerge-dark.webp"
                ></CarbonImage>
              </C4DCardInCardImage>
              <C4DCardEyebrow>Merge</C4DCardEyebrow>
              <C4DCardHeading aria-level={2}>
                Merge directly from the application
              </C4DCardHeading>
              <C4DCardCTAFooter>
                <ArrowRight size={20} />
              </C4DCardCTAFooter>
            </C4DCardInCard>
          </Link>
        </div>
      </div>
    </FlexGrid>
  );
}
