//@ts-ignore
import C4DCardEyebrow from "@carbon/ibmdotcom-web-components/es/components-react/card/card-eyebrow";
//@ts-ignore
import C4DCardFooter from "@carbon/ibmdotcom-web-components/es/components-react/card/card-footer";
//@ts-ignore
import C4DCardHeading from "@carbon/ibmdotcom-web-components/es/components-react/card/card-heading";
//@ts-ignore
import C4DContentBlockHeading from "@carbon/ibmdotcom-web-components/es/components-react/content-block/content-block-heading";
//@ts-ignore
import C4DContentItemParagraph from "@carbon/ibmdotcom-web-components/es/components-react/content-item/content-item-paragraph";
//@ts-ignore
import C4DFeatureSection from "@carbon/ibmdotcom-web-components/es/components-react/feature-section/feature-section";
//@ts-ignore
import C4DCard from "@carbon/ibmdotcom-web-components/es/components-react/card/card";
//@ts-ignore
import C4DImage from "@carbon/ibmdotcom-web-components/es/components-react/image/image";
//@ts-ignore
import C4DImageItem from "@carbon/ibmdotcom-web-components/es/components-react/image/image-item";

import Newsletter from "@/app/components/Newsletter";
import { FlexGrid, Row, Column } from "@carbon/react";
import Link from "next/link";

const containerStyle = {
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const mainContentStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const spacerStyle = {
  margin: "5rem 0",
  width: "100%",
};

export default function Onboard() {
  return (
    <div style={containerStyle}>
      <div style={mainContentStyle}>
        <FlexGrid fullWidth style={{ maxWidth: "1584px", margin: "0 auto" }}>
          <Row>
            <Column lg={16} md={8} sm={4}>
              <C4DFeatureSection media-alignment="right">
                <C4DImage slot="image" default-src={"/img/home_feature.webp"}>
                  <C4DImageItem
                    media="(min-width: 1312px)"
                    srcset={"/img/home_feature.webp"}
                  ></C4DImageItem>
                  <C4DImageItem
                    media="(min-width: 672px)"
                    srcset={"/img/home_feature.webp"}
                  ></C4DImageItem>
                  <C4DImageItem
                    media="(min-width: 320px)"
                    srcset={"/img/home_feature.webp"}
                  ></C4DImageItem>
                  <C4DImageItem
                    media="(min-width: 320px)"
                    srcset={"/img/home_feature.webp"}
                  ></C4DImageItem>
                  <C4DImageItem
                    media="(min-width: 320px)"
                    srcset={"/img/home_feature.webp"}
                  ></C4DImageItem>
                </C4DImage>
                <C4DCardEyebrow>10 min reading</C4DCardEyebrow>
                <C4DContentBlockHeading>
                  Understanding Merge Conflict Dynamics
                </C4DContentBlockHeading>
                <C4DContentItemParagraph slot="copy">
                  Our analysis of GitHub data reveals surprising patterns in
                  team size, commit frequency, and code ownership that influence
                  conflict rates.
                </C4DContentItemParagraph>

                <Link href="/blog/conflict-dynamics" passHref legacyBehavior>
                  <C4DCard
                    link
                    slot="footer"
                    href="/blog/conflict-dynamics"
                    cta-type="local"
                    color-scheme="inverse"
                  >
                    <C4DCardHeading>Read the article</C4DCardHeading>
                    <C4DCardFooter color-scheme="inverse"></C4DCardFooter>
                  </C4DCard>
                </Link>
              </C4DFeatureSection>
            </Column>
          </Row>

          <Row>
            <Column lg={16} md={8} sm={4}>
              <div style={spacerStyle}></div>
            </Column>
          </Row>

          <Row>
            <Column lg={16} md={8} sm={4}>
              <Newsletter />
            </Column>
          </Row>
        </FlexGrid>
      </div>
    </div>
  );
}
