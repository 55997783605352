"use client";

import { Button } from "@carbon/react";
import { ChevronUp, ChevronDown } from "@carbon/react/icons";
import { useEffect, useState } from "react";

export default function PageNavigation() {
  const [showNav, setShowNav] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Show navigation 47px earlier than the full viewport height
      setShowNav(window.scrollY > window.innerHeight - 47);

      // Check if we're at the bottom of the page
      const isBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 47; // 10px threshold
      setIsLastPage(isBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollPage = (direction: "up" | "down") => {
    const currentPosition = window.scrollY;
    const windowHeight = window.innerHeight;

    window.scrollTo({
      top:
        direction === "up"
          ? currentPosition - windowHeight
          : currentPosition + windowHeight,
      behavior: "smooth",
    });
  };

  if (!showNav) return null;

  return (
    <div
      className="navigation-container"
      style={{ top: "50vh", transform: "translateY(-50%)" }}
    >
      <Button
        kind="primary"
        hasIconOnly
        renderIcon={ChevronUp}
        iconDescription="Previous page"
        onClick={() => scrollPage("up")}
      />
      {!isLastPage && (
        <Button
          kind="primary"
          hasIconOnly
          renderIcon={ChevronDown}
          iconDescription="Next page"
          onClick={() => scrollPage("down")}
        />
      )}

      <style jsx>{`
        .navigation-container {
          position: fixed;
          left: 3rem; /* 12 tailwind units */
          right: 3rem; /* 12 tailwind units */
          display: none;
          justify-content: space-between;
          padding-left: 2rem; /* 8 tailwind units */
          padding-right: 2rem; /* 8 tailwind units */
        }
        @media (min-width: 768px) {
          .navigation-container {
            display: flex;
          }
        }
      `}</style>
    </div>
  );
}
