"use client";

import Hero from "./components/Hero";
import HomeFeature from "./components/HomeFeature";
import Onboard from "./components/Onboard";
import PageNavigation from "./components/PageNavigation";

export default function Home() {
  return (
    <>
      <Hero />
      <HomeFeature />
      <Onboard />
      <PageNavigation />
    </>
  );
}
